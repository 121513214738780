<template>
  <v-container fluid class="fill-height align-start" :class="{ 'justify-center': $apollo.loading }">
    <v-progress-circular
      v-if="$apollo.loading"
      :size="48"
      color="primary"
      indeterminate
      class="align-self-center"
      style="justify-self: center;"
    />
    <widget-starter v-if="!$apollo.loading && !(userWidgets || []).length" @widget:add="onWidgetAddClick" />
    <div v-if="!$apollo.loading && (userWidgets || []).length > 1">
      {{ userWidgets }}
    </div>
  </v-container>
</template>

<script>
import WidgetStarter from './components/widget-starter'

export default {
  components: { WidgetStarter },
  data() {
    return {
      widgetDefaults: {
        template: null,
        enabled: true,
        hostnames: null,
        options: []
      }
    }
  },
  apollo: {
    userWidgets: {
      query: require('@cpanel/graphql/user-widget-list.gql'),
      result(ApolloQueryResult) { this.onUserWidgetReceived(ApolloQueryResult) }
    },
    widgetTemplates: {
      query: require('@cpanel/graphql/widget-template-list.gql')
    }
  },
  methods: {
    onUserWidgetReceived(ApolloQueryResult) {
      const { data: { userWidgets }} = ApolloQueryResult
      if (userWidgets && userWidgets.length === 1) {
        this.$router.replace(`/widget/${userWidgets[0]._id}`)
      }
    },
    async addWidget(vars) {
      const { data: { addUserWidget: widget }} = await this.$apollo.mutate({
        mutation: require('@cpanel/graphql/user-widget-add.mutation.gql'),
        variables: { widget: { ...this.widgetDefaults, ...vars }}
      })
      return widget
    },
    async onWidgetAddClick() {
      if (this.widgetTemplates.length === 1) {
        const [template] = this.widgetTemplates
        try {
          await this.addWidget({ template: template._id })
          await this.$apollo.queries.userWidgets.refetch()
        } catch (err) {
          this.$store.dispatch('dialog/error', err)
        }
      } else if (this.widgetTemplates.length > 1) {
        this.$toast.error('В системе зарегистрировано несколько виджетов. Обратитесь в поддержку')
      } else {
        this.$toast.error('Нет зарегистрированных виджетов. Обратитесь в поддержку')
      }
    }
  }
}
</script>

<style scoped>

</style>
