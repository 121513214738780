<template>
  <div class="scrollable-layout-container">
    <div class="scrollable-layout-container-inner">
      <div class="scrollable-layout">
        <div class="scrollable-layout-inner">
          <slot name="header" />
          <div class="grey lighten-3" :style="{ 'height': `calc(100% - ${headerHeight}px - ${footerHeight}px)`, 'z-index': 0 }">
            <div style="overflow: auto; width: 100%; height: 100%; position: relative;">
              <div style="overflow: auto; width: 100%; height: 100%; position: absolute;">
                <slot />
              </div>
            </div>
          </div>
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerHeight: { type: null, default: () => 0 },
    footerHeight: { type: null, default: () => 0 }
  }
}
</script>

<style>
  .scrollable-layout-container {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: 100%;
  }

  .scrollable-layout-container-inner {
    flex: 1 1 auto;
    height: 100%;
    -webkit-box-flex: 1;
    box-sizing: border-box;
    border-left: none;
  }

  .scrollable-layout {
    width: 100%;
    height: 100%;
    display: block;
  }

  .scrollable-layout-inner {
    position: relative;
    box-sizing: border-box;
    height: 100%;
  }
</style>
