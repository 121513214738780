<template>
  <v-scrollable-layout :footer-height="hasChanges ? 64 : 0">
    <div v-if="!widgetTemplate" class="d-flex justify-center align-center" style="height: 100%;">
      <v-progress-circular
        :size="48"
        color="primary"
        indeterminate
      />
    </div>
    <component
      :is="widgetTemplate"
      v-if="widgetTemplate"
      v-model="model"
      :widget="userWidget"
    />
    <template v-slot:footer>
      <v-scroll-y-reverse-transition>
        <v-toolbar v-if="hasChanges" class="secondary" style="height: 64px;">
          <div class="d-flex justify-center align-center" style="height: 100%; width: 100%;">
            <v-btn class="mr-4" :disabled="saving" @click="onResetClick">Сбросить</v-btn>
            <v-btn color="primary" :disabled="saving" :loading="saving" @click="onSaveClick">Сохранить</v-btn>
          </div>
        </v-toolbar>
      </v-scroll-y-reverse-transition>
    </template>
  </v-scrollable-layout>
</template>

<script>
import VScrollableLayout from '@cpanel/components/scrollable-layout'
import WidgetTemplates from './widget-templates'

export default {
  components: { VScrollableLayout },
  props: {
    widgetId: { type: null, default: () => null }
  },
  data() {
    return {
      model: {},
      prevModel: {},
      saving: false
    }
  },
  apollo: {
    userWidget: {
      query: require('@cpanel/graphql/user-widget-get.gql'),
      variables() { return { widget: { _id: this.widgetId }} },
      result(ApolloQueryResult, key) { this.onWidgetReceived(ApolloQueryResult.data[key]) },
      error(err) { this.$store.dispatch('dialog/error', err) }
    }
  },
  computed: {
    hasChanges() {
      return !!Object
        .keys(this.model || {})
        .find(key => ((this.prevModel || {})[key] !== (this.model || {})[key]))
    },
    widgetTemplate() {
      if (!(this.userWidget || {}).template) return null
      if (!WidgetTemplates[this.userWidget.template.name]) return WidgetTemplates['default']
      return WidgetTemplates[this.userWidget.template.name]
    },
    widgetToSave() {
      const options = Object.keys(this.model).reduce((acc, key) => {
        acc.push({ name: key, value: this.model[key].toString() })
        return acc
      }, [])
      return {
        _id: this.userWidget._id,
        enabled: this.userWidget.enabled,
        hostnames: this.userWidget.hostnames,
        options
      }
    }
  },
  created() {
    this.prevModel = { ...this.model }
  },
  methods: {
    save() {
      return this.$apollo.mutate({
        mutation: require('@cpanel/graphql/user-widget-update.mutation.gql'),
        variables: { widget: this.widgetToSave }
      })
    },
    onWidgetReceived(widget) {
      const model = (widget.options || []).reduce((acc, el) => {
        acc[el.name] = el.value
        return acc
      }, {})
      this.model = model
      this.prevModel = model
    },
    async onSaveClick() {
      this.saving = true
      try {
        await this.save()
        this.prevModel = { ...this.model }
        this.$toast.success('Данные сохранены', { timeout: 2000 })
      } catch (err) {
        this.$store.dispatch('dialog/error', err)
      } finally {
        this.saving = false
      }
    },
    onResetClick() {
      this.model = { ...this.prevModel }
    }
  }
}
</script>

<style scoped>

</style>
