<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="auto" class="col-sm-8 col-md-6 text-center">
        <div class="py-6">
          <div class="pa-4">
            <v-icon size="96" class="primary--text text--darken-1">mdi-widgets</v-icon>
          </div>
          <h3>Создайте свой первый виджет</h3>
          <p class="pt-6 body-2">
            Создайте виджет и он появится на вашем сайте. Здесь вы сможете настроить внешний вид виджета,
            правила показа и другие параметры.
          </p>
        </div>
        <v-btn
          color="primary"
          :disabled="$apollo.loading"
          :loading="$apollo.loading"
          @click="$emit('widget:add')"
        >
          Добавить виджет
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
